import React, { useCallback, useEffect, useRef, useState } from "react";
import portfolioData from "./Data";
import "./home.scss";
import styled from "styled-components";
import { gsap } from "gsap";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import useScreenSize from "./useWidth";
import { useParams, useNavigate } from "react-router-dom";

export const Home = () => {
  // Add global CSS styles
  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      body.sidebar-open .overlay {
        pointer-events: auto;
      }
      
      body.sidebar-open {
        overflow: hidden;
      }
      
      /* Ensure sidebar is always on top when open */
      body.sidebar-open .sidebar {
        z-index: 20;
      }
      
      /* Ensure clicked outside works even when project is visible */
      body.sidebar-open .project-block {
        pointer-events: none;
      }
    `;

    // Append to document head
    document.head.appendChild(styleElement);

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(styleElement);
      document.body.classList.remove("sidebar-open");
    };
  }, []);
  const [open, setOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [project, setProject] = useState({});
  const app = useRef();
  const block = useRef();
  const sidebar = useRef();
  const topContent = useRef();
  const width = useScreenSize();
  const isMobile = width.width < 768;
  const { url } = useParams();
  const navigate = useNavigate();
  const urlProject = portfolioData.projects.find(
    (project) => url === project.url
  );

  useEffect(() => {
    if (url && urlProject) {
      setOpen(true);
      setProject(urlProject);
      handleOpen();
    }
  }, [url, urlProject]);

  const handleOpen = () => {
    gsap.to(block.current, { right: "0", delay: 0.2 });
    // Set focus to the project modal for better accessibility
    setTimeout(() => {
      if (block.current) {
        block.current.focus();
      }
    }, 300);
  };

  const handleClose = useCallback(() => {
    gsap.to(block.current, { right: "-100vw" });
    setOpen(false);
    navigate("/");
  }, [navigate]);

  const toggleSidebar = useCallback(() => {
    const newState = !openMenu;
    setOpenMenu(newState);

    // Animate the sidebar
    gsap.to(sidebar.current, {
      marginLeft: newState ? "0vw" : isMobile ? "-100vw" : "-35vw",
      duration: 0.3,
    });

    // Add overlay when sidebar is open
    if (newState) {
      gsap.to(".overlay", {
        opacity: 1,
        display: "block",
        duration: 0.3,
        zIndex: 10,
      });

      // Disable project list when sidebar is open
      document.body.classList.add("sidebar-open");

      // Set focus to the sidebar content
      setTimeout(() => {
        const firstFocusableElement = sidebar.current.querySelector(
          "button, [tabindex='0']"
        );
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }, 300);
    } else {
      gsap.to(".overlay", {
        opacity: 0,
        display: "none",
        duration: 0.3,
      });

      // Re-enable project list
      document.body.classList.remove("sidebar-open");
    }
  }, [openMenu, isMobile]);

  // Handle keyboard navigation for the project modal and sidebar
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        if (openMenu) {
          // Close sidebar first if it's open
          toggleSidebar();
        } else if (open) {
          // Then close project if sidebar is closed
          handleClose();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [open, openMenu, toggleSidebar, handleClose]);

  return (
    <Container ref={app}>
      <Overlay className="overlay" onClick={toggleSidebar} aria-hidden="true" />
      <HomeContent>
        <TopNav ref={topContent}>
          <AboutButton
            onClick={toggleSidebar}
            aria-expanded={openMenu}
            aria-controls="sidebar"
            aria-label="About Section"
          >
            About
          </AboutButton>
          <Name>Sam Gildea</Name>
        </TopNav>

        <Sidebar
          id="sidebar"
          ref={sidebar}
          className="sidebar"
          aria-hidden={!openMenu}
          role="region"
          aria-label="About Information"
        >
          <SidebarHeader>
            <SidebarTitle>About</SidebarTitle>
            <SidebarClose
              onClick={toggleSidebar}
              aria-label="Close about section"
              tabIndex={openMenu ? 0 : -1}
            >
              Close
            </SidebarClose>
          </SidebarHeader>

          <AboutInfo>
            <p>Developer/Designer.</p>
            <p>Computer Science/Design at Northeastern University.</p>
            <p>NYC/BOSTON</p>

            <SocialLinks>
              <SocialButton
                tabIndex={openMenu ? 0 : -1}
                aria-label="GitHub Profile"
                href="https://github.com/samgildea"
              >
                GitHub
              </SocialButton>
              <SocialButton
                tabIndex={openMenu ? 0 : -1}
                aria-label="LinkedIn Profile"
                href="https://www.linkedin.com/in/sam-gildea/"
              >
                LinkedIn
              </SocialButton>
              <SocialButton
                tabIndex={openMenu ? 0 : -1}
                aria-label="Email Contact"
                href="mailto:sam.c.gildea@gmail.com"
              >
                Email
              </SocialButton>
            </SocialLinks>
          </AboutInfo>
        </Sidebar>

        {open && (
          <ProjectBlock
            ref={block}
            className="project-block"
            role="dialog"
            aria-modal="true"
            aria-labelledby="project-title"
            tabIndex={-1}
          >
            <ProjectInfo>
              <CloseButton
                onClick={handleClose}
                aria-label="Close project details"
              >
                <HamburgerIcon />
              </CloseButton>

              <ProjectTitle id="project-title">{project.title}</ProjectTitle>
              <ProjectTimeline>{project.timeline}</ProjectTimeline>
              <ProjectDescription>{project.description}</ProjectDescription>

              {project.stack && project.stack.length > 0 && (
                <ProjectStackSection>
                  <StackHeading>Technologies Used:</StackHeading>
                  <ProjectStack>
                    {project.stack.map((tool, index) => (
                      <li key={`stack-${index}`}>{tool}</li>
                    ))}
                  </ProjectStack>
                </ProjectStackSection>
              )}

              {project.images && (
                <ProjectGallery>
                  {project.images.map((image, index) => {
                    const isVideo = project.id === 2 || project.id === 0;
                    const mediaLabel = `${project.title} ${
                      isVideo ? "video" : "image"
                    } ${index + 1}`;

                    return (
                      <MediaContainer key={`media-${index}`}>
                        {isVideo ? (
                          <video
                            autoPlay
                            muted
                            loop
                            controls
                            aria-label={mediaLabel}
                          >
                            <source src={image} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="project__image"
                            alt={mediaLabel}
                            src={image}
                          />
                        )}
                      </MediaContainer>
                    );
                  })}
                </ProjectGallery>
              )}
            </ProjectInfo>
          </ProjectBlock>
        )}

        <ListItemsContainer>
          <ProjectsHeading id="projects-heading">Projects</ProjectsHeading>
          <ListItems role="list" aria-labelledby="projects-heading">
            {portfolioData.projects.map((projectItem, index) => (
              <ListItem
                key={`project-${index}`}
                onClick={() => {
                  // Only open project if sidebar is closed
                  if (!openMenu) {
                    setOpen(true);
                    setProject(projectItem);
                    handleOpen();
                    navigate(`/${projectItem.url}`);
                  }
                }}
                onKeyDown={(e) => {
                  if ((e.key === "Enter" || e.key === " ") && !openMenu) {
                    e.preventDefault();
                    setOpen(true);
                    setProject(projectItem);
                    handleOpen();
                    navigate(`/${projectItem.url}`);
                  }
                }}
                tabIndex={openMenu ? -1 : 0}
                role="listitem"
                aria-label={`View ${projectItem.title} project`}
                className={openMenu ? "disabled" : ""}
              >
                <div className="project-title">{projectItem.title}</div>
                <ProjectPreview>
                  {projectItem.brief && (
                    <span className="brief">{projectItem.brief}</span>
                  )}
                </ProjectPreview>
              </ListItem>
            ))}
          </ListItems>
        </ListItemsContainer>
      </HomeContent>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  background-color: #fdfdfd;
`;

const AboutButton = styled.button`
  position: absolute;
  background-color: white;
  border: none;
  left: 48px;
  font-size: 24px;
  color: #ee312f;
  cursor: pointer;
  padding: 8px;
  transition: text-decoration 0.3s ease;

  &:focus,
  &:hover {
    outline: 2px solid #ee312f;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    left: 24px;
    font-size: 16px;
    padding: 4px;
  }
`;

const ProjectInfo = styled.div`
  position: relative;
`;

const ProjectTitle = styled.h2`
  padding-top: 48px;
  padding-left: 48px;
  font-size: 48px;
  width: 75%;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    padding-left: 24px;
    font-size: 32px;
    padding-top: 24px;
  }
`;

const AboutInfo = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  font-size: 32px;
  background-color: #ffffff;

  p {
    margin: 16px 0;
  }

  @media (max-width: 768px) {
    font-size: 24px;

    p {
      margin: 12px 0;
    }
  }
`;

const HamburgerIcon = styled(Hamburger)`
  width: 32px;
  height: 32px;

  svg {
    fill: red;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 48px;
  top: 52px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    outline: 2px solid #ee312f;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    right: 24px;
    top: 24px;
    padding: 4px;
  }
`;

const SidebarClose = styled.button`
  right: 24px;
  top: 24px;
  font-size: 28px;
  margin: 0;
  color: #ee312f;
  background: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: 2px solid #ee312f;
    border-radius: 4px;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

const ProjectBlock = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 5; /* Lower than sidebar and topnav */
  position: fixed;
  top: 72px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #ee312f;
  right: -100vw;
  background-color: #fdfdfd;
  overscroll-behavior: contain;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ProjectTimeline = styled.div`
  color: #ee312f;
  font-size: 18px;
  padding-left: 48px;
  padding-top: 8px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    padding-left: 24px;
    font-size: 16px;
  }
`;

const ProjectDescription = styled.div`
  font-size: 24px;
  padding-top: 8px;
  padding-right: 48px;
  padding-left: 48px;
  line-height: 1.5;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 18px;
    padding-top: 8px;
  }
`;

const StackHeading = styled.h3`
  font-size: 24px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ProjectStackSection = styled.div`
  padding-left: 48px;
  padding-right: 48px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const ProjectStack = styled.ul`
  padding-left: 24px;
  font-size: 20px;
  list-style-type: disc;

  li {
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ProjectGallery = styled.div`
  padding: 0 48px 48px;

  @media (max-width: 768px) {
    padding: 0 24px 24px;
  }
`;

const MediaContainer = styled.div`
  margin-bottom: 32px;

  video,
  img {
    width: 100%;
    border: 1px solid #ee312f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  video {
    display: block;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: none;
  opacity: 0;
`;

const SidebarHeader = styled.div`
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #ee312f;
`;

const SidebarTitle = styled.h2`
  font-size: 28px;
  margin: 0;
  color: #ee312f;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
`;

const SocialButton = styled.a`
  background-color: #ee312f;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: #d12b29;
    outline: none;
    color: white;
    text-decoration: none;

    transform: translateY(-2px);
  }
`;

const Sidebar = styled.div`
  width: 35vw;
  height: 100vh;
  margin-left: -35vw;
  border-right: 1px solid #ee312f;
  position: fixed;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 20; /* Higher than everything else */
  transition: margin-left 0.3s ease;
  box-shadow: 4px 0 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100vw;
    margin-left: -100vw;
  }
`;

const TopNav = styled.div`
  background-color: white;
  width: 100vw;
  position: fixed;
  z-index: 15; /* Higher than project, lower than sidebar */
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ee312f;
`;

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #ee312f;
  overscroll-behavior: contain;
  font-weight: lighter;
`;

const ProjectsHeading = styled.h2`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const ListItems = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListItem = styled.div`
  cursor: pointer;
  font-size: 48px;
  color: #ee312f;
  padding: 48px;
  line-height: 1.2;
  background-color: #fdfdfd;
  border-bottom: 1px solid #ee312f;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .project-title {
    font-size: 48px;
    margin-bottom: 12px;
    position: relative;
    z-index: 1;
  }

  &:focus {
    outline: none;
    color: white;
    background-color: #ee312f;
  }

  &:hover {
    color: white;
    background-color: #ee312f;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(0.5);

    &:hover,
    &:focus {
      background-color: #fdfdfd;
      color: #ee312f;
      padding: 48px;
    }
  }

  @media (max-width: 768px) {
    padding: 32px 24px;

    .project-title {
      font-size: 24px;
    }

    &:hover {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
`;

const ProjectPreview = styled.div`
  font-size: 20px;
  margin-top: 8px;
  font-weight: normal;
  opacity: 0.9;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Name = styled.div`
  font-size: 32px;
  color: #ee312f;
  text-decoration: none;
  width: 100%;
  text-align: center;
  font-weight: lighter;
  line-height: 1;
  height: fit-content;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const ListItemsContainer = styled.div`
  overflow-y: auto;
  height: fit-content;
  margin-top: 72px;
  width: 100vw;
  overscroll-behavior: contain;
`;
