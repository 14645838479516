import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Home } from "./Home";
import MetaTags from "react-meta-tags";

import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <MetaTags>
      <meta name="description" content="Sam Gildea - developer/designer" />
    </MetaTags>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Single dynamic route that captures all project URLs */}
      <Route path="/:url" element={<Home />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
